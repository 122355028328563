import Navbar from "./Navbar"
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

function BaseTemplate(props) {
    return (
        <div className="overscroll-none no-scrollbar">
            <Analytics />
            <SpeedInsights />
            <div className="dark:bg-black min-h-screen dark:text-white">
            {/* <div className="min-h-screen bg-slate-100 dark:bg-slate-800 dark:text-white grid"> */}
                <div className="mx-16 w-full sm:p-12 pt-10 p-4 max-w-screen-lg mx-auto border-x-slate-300">
                    <Navbar currentRoute = {props.currentRoute} />
                    <div className="mb-16">
                        {props.children}
                    </div>
                </div>
            </div>
            
            {/* <div className="border-t border-gray-300 px-16 py-1 text-sm max-w-screen-md mx-auto">
                <div>&copy; 2024</div>
            </div> */}

            <div className="bg-white dark:bg-black w-full fixed bottom-0">
                <div className="bg-white dark:bg-black border-t w-full dark:text-white dark:border-gray-700 border-gray-200  py-2 text-sm">
                    <div className="max-w-screen-lg mx-auto sm:px-12 px-4">&copy; 2025</div>
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}

export default BaseTemplate