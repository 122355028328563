import { useParams, useSearchParams } from "react-router-dom";
import BaseTemplate from "../Components/BaseTemplate";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet"
import { MathJax, MathJaxContext } from "better-react-mathjax"
import { ArrowRightIcon } from "@heroicons/react/24/solid";

function BlackScholes(props) {
    let { projectId } = useParams()

    let baseUrl = "https://api-prod-v1.mitillos.me"
    let devUrlOverride = false

    if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !devUrlOverride) { 
        baseUrl = "http://127.0.0.1:5000"
    }

    // API Request Submitted
    const [loading, setLoading] = useState(false)

    // Get Search Params
    const [searchParams, setSearchParams] = useSearchParams()

    // Inputs
    const [inputs, setInputs] = useState({})
    const [oldInputs, setOldInputs] = useState({});

    // API Retrieved Data
    const [data, setData] = useState({})
    const [showData, setShowData] = useState(false)
    const [apiError, setApiError] = useState(false)

     // Handle change of inputs
     const onChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        setSearchParams({ ...inputs, [e.target.name]: e.target.value })
    }

    // Handle API Request
    const getBlackScholesPrices = (customInputs) => {
        // Handles second request feature reset
        let myInputs = inputs

        if (customInputs && customInputs["strike_price"]) myInputs = customInputs

        setApiError(false)
        setData({})
        setOldInputs(myInputs)

        let preSubmitError = false

        // Carry input validation before sending API request
        if (!myInputs["strike_price"]) {
            setApiError("Strike Price not defined")
            preSubmitError = true
        }

        if (!myInputs["spot_price"] && !preSubmitError) {
            setApiError("Spot Price not defined")
            preSubmitError = true
        }

        if (!myInputs["risk_free_rate"] && !preSubmitError) {
            setApiError("Risk Free Rate not defined")
            preSubmitError = true
        }

        if (!myInputs["volatility"] && !preSubmitError) {
            setApiError("Volatility not defined")
            preSubmitError = true
        }

        if (!myInputs["time_to_maturity"] && !preSubmitError) {
            setApiError("Time to Maturity not defined")
            preSubmitError = true
        }

        // Submit API Request after validating input
        if (!preSubmitError) {
            setLoading(true)
            setShowData(false)

            fetch(`${baseUrl}/option_pricing/black_scholes/${myInputs["strike_price"]}/${myInputs["spot_price"]}/${myInputs["time_to_maturity"]}/${myInputs["risk_free_rate"]}/${myInputs["volatility"]}`)
                .then((response) => response.json())
                .then((data) => {
                    // Data Retrieved - Stop Loading
                    setLoading(false)
                    // Save Received Data
                    setData(data?.data)
                    // Check for Error
                    if (data.error) {
                        setApiError(data.message)
                        setData({})
                        setShowData(false)
                    } else {
                        setShowData(true)
                    }
                })
                .catch(error => {
                    setData({})
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        let strike_price_query = searchParams.get("strike_price")
        let spot_price_query = searchParams.get("spot_price")
        let risk_free_rate_query = searchParams.get("risk_free_rate")
        let volatility_query = searchParams.get("volatility")
        let time_to_maturity_query = searchParams.get("time_to_maturity")

        let newInputs = {
            ...inputs
        }

        if (strike_price_query) newInputs["strike_price"] = strike_price_query
        if (spot_price_query) newInputs["spot_price"] = spot_price_query
        if (risk_free_rate_query) newInputs["risk_free_rate"] = risk_free_rate_query
        if (volatility_query) newInputs["volatility"] = volatility_query
        if (time_to_maturity_query) newInputs["time_to_maturity"] = time_to_maturity_query

        setInputs(newInputs)
        
        if (strike_price_query && spot_price_query && risk_free_rate_query && volatility_query && time_to_maturity_query) {
            getBlackScholesPrices(newInputs)
        }
    }, []);

    return (
    <BaseTemplate currentRoute = "Projects">
        <Helmet>
            <title>Black Scholes Option Pricing - Andreas Mitillos</title>
        </Helmet>
        
        <div className="mb-8">
            <h1 className='font-bold text-4xl'><span className="text-slate-500">Black Scholes</span> Option Pricing</h1>
            <p className='text-gray-500 text-sm mono'>{projectId}</p>
        </div>

        <div className="mb-8">
            <h2 className="text-2xl font-bold mb-3">Introduction</h2>
            <p style={{ textAlign: "justify" }}>
                This project aims to provide a simple and intuitive way to price European call options using the Black-Scholes model. The Black-Scholes model is a mathematical model used for pricing options contracts. The model was first introduced by Fischer Black and Myron Scholes in 1973 and later refined by Robert Merton. The model is widely used by options traders to determine the fair market value of an option at any given point in time.
            </p>
            <h2 className="text-2xl font-bold mt-6">The Black-Scholes Formula</h2>
            <div className="text-center mt-6">
                <MathJaxContext>
                    {/* <MathJax>{"\\(\\frac{10}{4x} \\approx 2^{12}\\)"}</MathJax> */}
                    <MathJax>{`\\begin{align} 
                        C &= \\Phi(d_1)S_0 - \\Phi(d_2) Ke^{-rT} \\\\
                        P &= \\Phi(-d_2)Ke^{-rT} - \\Phi(-d_1)S_0 
                        \\end{align}`}
                    </MathJax>
                    <MathJax>{"\\begin{align} d_1 = \\frac{\\ln \\left (\\frac{S_0}{K} \\right ) + \\left (r + \\frac{1}{2}\\sigma^2 \\right ) T}{\\sigma\\sqrt{T}} \\quad \\text{ and } \\quad d_2 = d_1 - \\sigma\\sqrt{T}  \\end{align}"}</MathJax>
                </MathJaxContext>
            </div>
            where:
            <MathJaxContext>
                <ul className="list-disc list-inside mt-2 ml-6">
                    <li className="pb-2"><MathJax inline>{"\\( C \\)"}</MathJax> is the price of the call option</li>
                    <li className="pb-2"><MathJax inline>{"\\( \\Phi(\\cdot) \\)"}</MathJax> is the cumulative distribution function of the standard normal distribution</li>
                    <li className="pb-2"><MathJax inline>{"\\( S_0 \\)"}</MathJax> is the spot price of the underlying asset</li>
                    <li className="pb-2"><MathJax inline>{"\\( K \\)"}</MathJax> is the strike price of the option</li>
                    <li className="pb-2"><MathJax inline>{"\\( r \\)"}</MathJax> is the risk-free interest rate</li>
                    <li className="pb-2"><MathJax inline>{"\\( T \\)"}</MathJax> is the time to maturity</li>
                    <li><MathJax inline>{"\\( \\sigma \\)"}</MathJax> is the volatility of the underlying asset</li>
                </ul>
            </MathJaxContext>
        </div>

        <div className="mb-8">
            <h2 className="font-bold text-2xl">Calculator</h2>
            {/* <p className="text-gray-500">Define the dates for which the backtesting will run.</p> */}
            <div className="sm:flex sm:space-x-6 mt-2">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Strike Price</label>
                    <div className="flex items-center">
                        <MathJaxContext>
                            <MathJax inline>{"\\( K = \\)"}</MathJax>
                        </MathJaxContext>
                        <input name = "strike_price" onChange={onChange.bind(this)} value={inputs["strike_price"]} type="number" className="border-0 mono pl-2 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="Number" />
                    </div>
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Spot Price</label>
                    <div className="flex items-center">
                        <MathJaxContext>
                            <MathJax inline>{"\\( S_0 = \\)"}</MathJax>
                        </MathJaxContext>
                        <input name = "spot_price" onChange={onChange.bind(this)} value={inputs["spot_price"]} type="number" className="border-0 mono pl-2 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="Number" />
                    </div>
                </div>
            </div>

            <div className="sm:flex sm:space-x-6 mt-2">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Risk Free Rate</label>
                    <div className="flex items-center">
                        <MathJaxContext>
                            <MathJax inline>{"\\( r = \\)"}</MathJax>
                        </MathJaxContext>
                        <input name = "risk_free_rate" onChange={onChange.bind(this)} value={inputs["risk_free_rate"]} type="number" className="border-0 mono pl-2 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="Decimal" />
                    </div>
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Volatility</label>
                    <div className="flex items-center">
                        <MathJaxContext>
                            <MathJax inline>{"\\( \\sigma = \\)"}</MathJax>
                        </MathJaxContext>
                        <input name = "volatility" onChange={onChange.bind(this)} value={inputs["volatility"]} type="number" className="border-0 mono pl-2 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="Decimal" />
                    </div>
                </div>
            </div>

            <div className="sm:flex sm:space-x-6 mt-2">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Time to Maturity </label>
                    <div className="flex items-center">
                        <MathJaxContext>
                            <MathJax inline>{"\\( T = \\)"}</MathJax>
                        </MathJaxContext>
                        <input name = "time_to_maturity" onChange={onChange.bind(this)} value={inputs["time_to_maturity"]} type="number" className="border-0 mono pl-2 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="In years" />
                    </div>
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2"></div>
            </div>
        </div>

        <div className="mb-8">
            {loading ? <button disabled className="bg-gray-700 dark:bg-gray-200 dark:text-black text-white text-sm px-4 py-2 cursor-not-allowed">
                <div class="mx-5 h-5 w-5 animate-spin rounded-full border-2 border-t-white border-gray-600 dark:border-t-black dark:border-gray-200" />
            </button> : <button onClick={getBlackScholesPrices} className="bg-black dark:bg-white dark:text-black text-white text-sm px-4 py-2 hover:underline inline-flex items-center">
                Calculate <ArrowRightIcon className="size-5 ml-2" />
            </button>}
        </div>

        {showData ? <div className="mb-8">
            <h2 className="text-2xl font-bold mb-3">Results</h2>
            <p className="mb-6">
                The price of the european call and put options, with the parameters provided, are as follows:
            </p>
            <div className="text-center mb-6">
                <MathJaxContext>
                    <MathJax>
                        {`\\( \\begin{align} 
                            C &= ${Math.round(data?.call_price * 10000) / 10000 } \\quad \\text{and} \\quad P= ${Math.round(data?.put_price * 10000) / 10000} 
                        \\end{align} \\)`}
                    </MathJax>
                </MathJaxContext>
            </div>

            <h3 className="text-xl font-bold mb-3">Greeks for European Call Options</h3>

            <div className="text-center mb-6">
                <MathJaxContext>
                    <MathJax>{`\\( 
                        \\displaystyle \\begin{align}
                            \\text{Delta} = \\Delta_C &= \\frac{ \\partial C}{\\partial S_0} = \\Phi(d_1) \\approx ${Math.round(data?.call_delta * 10000) / 10000} \\\\
                            \\text{Gamma} = \\Gamma_C &= \\frac{ \\partial^2 C}{\\partial S_0^2} = \\frac{\\phi(d_1)}{S_0\\sigma\\sqrt{T}} \\approx ${Math.round(data?.call_gamma * 10000) / 10000} \\\\
                            \\text{Vega} = \\mathcal{V}_C &= \\frac{ \\partial C}{\\partial \\sigma} = S_0 \\phi(d_1) \\sqrt{T} \\approx ${Math.round(data?.call_vega * 10000) / 10000} \\\\
                            \\text{Rho} = \\rho_C &= \\frac{\\partial C}{\\partial r} = KTe^{-rT} \\Phi(d_2) \\approx ${Math.round(data?.call_rho * 10000) / 10000} \\\\
                            \\text{Theta} = \\Theta_C &= -\\frac{\\partial C}{\\partial T} = -\\frac{S_0 \\phi(d_1) \\sigma}{2\\sqrt{T}} - rKe^{-rT} \\Phi(d_2) \\approx ${Math.round(data?.call_theta * 10000) / 10000}
                        \\end{align}
                    \\)`}</MathJax>
                </MathJaxContext>
            </div>

            <div className="mb-6">
                where <MathJaxContext><MathJax inline>{"\\( \\phi \\)"}</MathJax></MathJaxContext> the probability density function of the standard normal distribution.
            </div>
            
            <h3 className="text-xl font-bold mb-3">Greeks for European Put Options</h3>
            <div className="text-center mb-6">
                <MathJaxContext>
                    <MathJax>{`\\( 
                        \\displaystyle \\begin{align}
                            \\text{Delta} = \\Delta_P &= \\frac{ \\partial P}{\\partial S_0} = \\Phi(d_1) - 1 \\approx ${Math.round(data?.put_delta * 10000) / 10000} \\\\
                            \\text{Gamma} = \\Gamma_P &= \\frac{ \\partial^2 P}{\\partial S_0^2} = \\frac{\\phi(d_1)}{S_0\\sigma\\sqrt{T}} \\approx ${Math.round(data?.put_gamma * 10000) / 10000} \\\\
                            \\text{Vega} = \\mathcal{V}_P &= \\frac{ \\partial P}{\\partial \\sigma} = S_0 \\phi(d_1) \\sqrt{T} \\approx ${Math.round(data?.put_vega * 10000) / 10000} \\\\
                            \\text{Rho} = \\rho_P &= \\frac{\\partial P}{\\partial r} = -KTe^{-rT} \\Phi(-d_2) \\approx ${Math.round(data?.put_rho * 10000) / 10000} \\\\
                            \\text{Theta} = \\Theta_P &= -\\frac{\\partial P}{\\partial T} = -\\frac{S_0 \\phi(d_1) \\sigma}{2\\sqrt{T}} + rKe^{-rT} \\Phi(-d_2) \\approx ${Math.round(data?.put_theta * 10000) / 10000}
                        \\end{align}
                    \\)`}</MathJax>
                </MathJaxContext>
            </div>

            <div>
                where <MathJaxContext><MathJax inline>{"\\( \\phi \\)"}</MathJax></MathJaxContext> the probability density function of the standard normal distribution.
            </div>

            <div className="text-right">
                <MathJaxContext>
                    <MathJax>
                        {"\\( \\square \\)"}
                    </MathJax>
                </MathJaxContext>
            </div>
        </div> : ""}

        { apiError ? <div className="bg-slate-100 py-3 px-2 border-t-4 text-sm border-red-500 text-slate-600 dark:text-slate-400 dark:bg-gray-950">
            <h4 className="text-lg text-red-600 font-medium">Something went wrong.</h4>
            {apiError}
        </div> : "" }
    </BaseTemplate>)
}

export default BlackScholes