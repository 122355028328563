import { useParams, useSearchParams } from "react-router-dom";
import BaseTemplate from "../Components/BaseTemplate";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import annotationPlugin from "chartjs-plugin-annotation"
import { Helmet } from "react-helmet"
import { MathJax, MathJaxContext } from "better-react-mathjax"

import { 
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    Legend
 } from "chart.js"
import { Line } from "react-chartjs-2"

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    Legend,
    annotationPlugin
)

function TimeseriesChart(props) {
    const show = props.show
    if (show && props.data?.labels) {
        return <Line
        data = {props.data}
        options = {props.options}
    ></Line>
    } else {
        return <div>not show</div>
    }
}

function BacktestingPairsTradeProject(props) {
    let { projectId } = useParams()

    let baseUrl = "https://api-prod-v1.mitillos.me"
    let devUrlOverride = false

    if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !devUrlOverride) { 
        baseUrl = "http://127.0.0.1:5000"
    }

    // API Request Submitted
    const [loading, setLoading] = useState(false)

    // Get Search Params
    const [searchParams, setSearchParams] = useSearchParams()

    // Inputs
    const [inputs, setInputs] = useState({})
    const [oldInputs, setOldInputs] = useState({});

    // API Retrieved Data
    const [data, setData] = useState({})
    const [showData, setShowData] = useState(false)
    const [apiError, setApiError] = useState(false)

    // Data for Plots
    const [priceData, setPriceData] = useState({})
    const [spreadData, setSpreadData] = useState({})
    const [regressionCoefficientsData, setRegressionCoefficientsData] = useState({})
    const [zScoreData, setZscoreData] = useState({})

    // Handle change of inputs
    const onChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        setSearchParams({ ...inputs, [e.target.name]: e.target.value })
    }

    let searchParamBacktest = false

    useEffect(() => {
        let stock_a_query = searchParams.get("stock_a")
        let stock_b_query = searchParams.get("stock_b")

        let start_date_query = searchParams.get("start_date")
        let end_date_query = searchParams.get("end_date")

        let entry_z_query = searchParams.get("entry_z")
        let exit_z_query = searchParams.get("exit_z")

        let regression_period_query = searchParams.get("regression_period")

        let newInputs = {
            ...inputs
        }

        if (stock_a_query) newInputs["stock_a"] = stock_a_query
        if (stock_b_query) newInputs["stock_b"] = stock_b_query

        if (start_date_query) newInputs["start_date"] = start_date_query
        if (end_date_query) newInputs["end_date"] = end_date_query

        if (entry_z_query) newInputs["entry_z"] = entry_z_query
        if (exit_z_query) newInputs["exit_z"] = exit_z_query

        if (regression_period_query) newInputs["regression_period"] = regression_period_query

        setInputs(newInputs)
        
        if (stock_a_query && stock_b_query && start_date_query && end_date_query && entry_z_query && exit_z_query && regression_period_query) {
            getBacktestResults(newInputs)
        }
    }, []);

    // Handle API Request
    const getBacktestResults = (customInputs) => {
        // Handles second request feature reset
        let myInputs = inputs

        if (customInputs && customInputs["stock_a"]) myInputs = customInputs

        setApiError(false)
        setData({})
        setOldInputs(myInputs)

        let preSubmitError = false

        // Carry input validation before sending API request
        if (!myInputs["stock_a"]) {
            setApiError("Stock A not defined")
            preSubmitError = true
        }

        if (!myInputs["stock_b"] && !preSubmitError) {
            setApiError("Stock B not defined")
            preSubmitError = true
        }

        if (!myInputs["start_date"] && !preSubmitError) {
            setApiError("Start Date not defined")
            preSubmitError = true
        }

        if (!myInputs["end_date"] && !preSubmitError) {
            setApiError("End Date not defined")
            preSubmitError = true
        }

        if (!myInputs["entry_z"] && !preSubmitError) {
            setApiError("Entry Z - Score not defined")
            preSubmitError = true
        }

        if (!myInputs["exit_z"] && !preSubmitError) {
            setApiError("Exit Z - Score not defined")
            preSubmitError = true
        }

        if (!myInputs["regression_period"] && !preSubmitError) {
            setApiError("Regression Period not Defined")
            preSubmitError = true
        }

        // Submit API Request after validating input
        if (!preSubmitError) {
            setLoading(true)
            setShowData(false)

            fetch(`${baseUrl}/pairs_trade/backtest/${myInputs["stock_a"]}/${myInputs["stock_b"]}/${myInputs["start_date"]}/${myInputs["end_date"]}/${myInputs["regression_period"]}/${myInputs["entry_z"]}/${myInputs["exit_z"]}/10000`)
            // fetch(`http://127.0.0.1:5000/pairs/get?stock_a=${inputs["stock_a"]}&stock_b=${inputs["stock_b"]}&start_date=${inputs["start_date"]}&end_date=${inputs["end_date"]}`)
                .then((response) => response.json())
                .then((data) => {
                    // Data Retrieved - Stop Loading
                    setLoading(false)
                    // Save Received Data
                    setData(data?.data)
                    // Check for Error
                    if (data.error) {
                        setApiError(data.message)
                        setData({})
                        setPriceData({})
                        setSpreadData({})
                        setRegressionCoefficientsData({})
                        setZscoreData({})
                        setShowData(false)
                    }
                })
                .catch(error => {
                    setData({})
                    setLoading(false)
                })
        }

    }

    useEffect(() => {
        if (data && inputs && inputs["stock_a"] && data[inputs["stock_a"]] && inputs["stock_b"] && data[inputs["stock_b"]]) {
            // Create Z Score Data for the Chart
            setZscoreData({
                labels: data.dates,
                datasets: [
                    {
                        label: "Z - Score",
                        data: data["z_score"],
                        backgroundColor: "rgb(108,114,127)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "gray"
                    }
                ]
            })

            // Create Regression Data for the Chart
            setRegressionCoefficientsData({
                labels: data.dates,
                datasets: [
                    {
                        label: "Beta",
                        data: data["beta_stock_b"],
                        backgroundColor: "rgb(78,128,238)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "rgb(78,128,238)",
                        yAxisID: 'leftY',
                        // borderColor: "",
                        // tension: 0.4
                    },
                    {
                        label: "Alpha",
                        data: data["alpha"],
                        backgroundColor: "rgb(108,114,127)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "rgb(108,114,127)",
                        yAxisID: 'rightY',
                        // tension: 0.4
                    }
                ]
            })

            // Create Spread Data for the Chart
            setSpreadData({
                labels: data.dates,
                datasets: [
                    {
                        label: "Spread",
                        data: data["spread"],
                        backgroundColor: "rgb(108,114,127)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "rgb(108,114,127)"
                    }
                ]
            })
            
            // Create Price Data for the Chart
            setPriceData({
                labels: data.dates,
                datasets: [
                    {
                        label: inputs["stock_a"],
                        data: data[inputs["stock_a"]],
                        backgroundColor: "rgb(108,114,127)",
                        borderColor: "rgb(108,114,127)",
                        pointStyle: true,
                        pointRadius: 0.5,
                        borderWidth: 1,
                        pointHoverRadius: 3,
                        yAxisID: 'leftY',
                    },
                    {
                        label: inputs["stock_b"],
                        data: data[inputs["stock_b"]],
                        backgroundColor: "rgb(78,128,238)",
                        borderColor: "rgb(78,128,238)",
                        pointStyle: true,
                        pointRadius: 0.5,
                        borderWidth: 1,
                        pointHoverRadius: 3,
                        yAxisID: 'rightY',
                    }
                ]
            })  

            // Allow Data to be displayed in render
            setShowData(true)
        }
    }, [data]);

    // Chart Options
    const options = {
        responsive: true,
        scales: {
            leftY: { type: 'linear', display: true, position: 'left', },
            rightY: { type: 'linear', display: true, position: 'right', grid: { drawOnChartArea: false } }
        }
    }

    return (
    <BaseTemplate currentRoute = "Projects">
        <Helmet>
            <title>{showData && oldInputs["stock_a"] && oldInputs["stock_b"] ? `${oldInputs["stock_a"]} / ${oldInputs["stock_b"]} ` : ""}Backtesting Pairs Trade - Andreas Mitillos</title>
        </Helmet>
        
        <div className="mb-8">
            <h1 className='font-bold text-4xl'><span className="text-slate-500">Backtesting</span> Pairs Trade - Cointegration</h1>
            <p className='text-gray-500 text-sm mono'>{projectId}</p>
        </div>

        <div className="mb-8">
            <h2 className="font-semibold text-2xl">1. Define Stock Tickers</h2>
            <p className="text-gray-500">Start by selecting the two stock tickers</p>
            <div className="sm:flex sm:space-x-6">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Stock A Ticker</label>
                    <input name = "stock_a" onChange = {onChange.bind(this)} value = {inputs["stock_a"]} type="text" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., AMD" />
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Stock B Ticker</label>
                    <input name = "stock_b" onChange = {onChange.bind(this)} value = {inputs["stock_b"]} type="text" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., NVDA" />
                </div>
            </div>
        </div>

        <div className="mb-8">
            <h2 className="font-semibold text-2xl">2. Define Backtesting Period</h2>
            <p className="text-gray-500">Define the dates for which the backtesting will run.</p>
            <div className="sm:flex sm:space-x-6">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Start Date</label>
                    <input name = "start_date" onChange = {onChange.bind(this)} value = {inputs["start_date"]} type="date" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" />
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">End Date</label>
                    <input name = "end_date" onChange = {onChange.bind(this)} value = {inputs["end_date"]} type="date" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" />
                </div>
            </div>
        </div>

        <div className="mb-8">
            <h2 className="font-semibold text-2xl">3. Define Entry and Exit Thresholds</h2>
            <p className="text-gray-500">Define the entry and exit z-scores to enter and exit trades.</p>
            <div className="sm:flex sm:space-x-6">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Entry Z-Score</label>
                    <input name = "entry_z" onChange = {onChange.bind(this)} value = {inputs["entry_z"]} type="float" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., 2.0" />
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Exit Z-Score</label>
                    <input name = "exit_z" onChange = {onChange.bind(this)} value = {inputs["exit_z"]} type="number" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., 1.0" />
                </div>
            </div>
        </div>

        <div className="mb-8">
            <h2 className="font-semibold text-2xl">4. Define Rolling Regression Period</h2>
            <p className="text-gray-500">Define the number of trading days for which the cointegration model will be trained on. This number must be between 10 and 100.</p>
            <div className="sm:flex sm:space-x-6">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Regression Period</label>
                    <input name = "regression_period" onChange = {onChange.bind(this)} value = {inputs["regression_period"]} type="number" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., 100" />
                </div>
                {/* <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Exit Z-Score</label>
                    <input type="number" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., 1.0" />
                </div> */}
            </div>
        </div>

        <div className="bg-slate-100 py-3 px-2 border-t-4 mb-6 text-sm border-slate-500 text-slate-500 dark:text-slate-400 dark:bg-black">
            <h4 className="text-lg text-slate-500 font-medium">Notes</h4>
            <ul className="list-disc ml-6">
                <li>Yahoo Finance daily price data is used</li>
                <li>This web-application is intended for demonstration purposes ONLY</li>
                <li>An OLS is used to train the cointegration model on a rolling period defined in section 4</li>
                <li>On the starting date, the model will be trained on the number of trading days defined by the regression period</li>
                <li>Adjusted closing prices are used</li>
            </ul>
        </div>

        <div className="mb-8">
            {loading ? <button disabled className="bg-gray-700 dark:bg-gray-200 dark:text-black text-white text-sm px-4 py-2 cursor-not-allowed">
                <div class="mx-5 h-5 w-5 animate-spin rounded-full border-2 border-t-white border-gray-600 dark:border-t-black dark:border-gray-200" />
            </button> : <button onClick={getBacktestResults} className="bg-black dark:bg-white dark:text-black text-white text-sm px-4 py-2 hover:underline inline-flex items-center">
                Backtest <ArrowRightIcon className="size-5 ml-2" />
            </button>}
        </div>

        {showData ? 
        <div className="my-4">
            <h1 className='font-bold text-4xl'>Backtesting Report</h1>
            <p className="text-gray-500 dark:text-gray-400 pt-4 text-sm">
                {data["stock_a_name"]} ({oldInputs["stock_a"]}) and {data["stock_b_name"]} ({oldInputs["stock_b"]}) backtesting report using cointegration pairs trade strategy for the period of {oldInputs["start_date"]} to {oldInputs["end_date"]} using a {oldInputs["regression_period"]} trading day rolling regression period.
            </p>

            <p className="text-gray-500 dark:text-gray-400 pt-1 text-sm">
                We long the spread (i.e., long {oldInputs["stock_a"]} and short {oldInputs["stock_b"]}) when <MathJaxContext><MathJax inline>{`\\(Z < ${-oldInputs["entry_z"]} \\)`}</MathJax></MathJaxContext>. We exit this position when <MathJaxContext><MathJax inline>{`\\(Z \\geq -${oldInputs["exit_z"]} \\)`}</MathJax></MathJaxContext>.
            </p>

            <p className="text-sm border-b text-gray-500 dark:text-gray-400 border-slate-300 dark:border-slate-600 pt-1 pb-4 mb-5">
                We short the spread (i.e., long {oldInputs["stock_b"]} and short {oldInputs["stock_a"]}) when <MathJaxContext><MathJax inline>{`\\(Z > ${oldInputs["entry_z"]} \\)`}</MathJax></MathJaxContext>. We exit this position when <MathJaxContext><MathJax inline>{`\\(Z \\leq ${oldInputs["exit_z"]} \\)`}</MathJax></MathJaxContext>.
            </p>

            <h2 className="text-xl font-semibold">
                Trade Report
            </h2>
            <p className="mb-2 text-sm">
                Initial Capital is 10,000.
                This is the trade report between the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.
            </p>

            <table className="w-full text-sm text-right text-xs">
                {/* bg-slate-100 dark:bg-slate-900 */}
                <tr className="border-b">
                    <th className="py-0.5"></th>
                    <th className="py-0.5 border-x px-2">Total</th>
                    <th className="py-0.5">{oldInputs["stock_a"]}</th>
                    <th className="py-0.5">{oldInputs["stock_b"]}</th>
                </tr>
                <tr className="font-mono">
                    <td className="py-0.5 text-left font-sans">Total Profit / Loss</td>
                    <td className="py-0.5 border-x px-2">{(Math.round((10000 * (data?.total_net_returns) - 10000) * 100) / 100)?.toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.total_stock_a_returns * 100)) / 100)?.toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.total_stock_b_returns * 100)) / 100)?.toLocaleString()}</td>
                </tr>
                <tr className="font-mono">
                    <td className="py-0.5 text-left font-sans">Profit</td>
                    <td className="py-0.5 border-x px-2">{(Math.round((data?.total_profit) * 100) / 100)?.toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.profit_stock_a_returns * 100)) / 100)?.toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.profit_stock_b_returns * 100)) / 100)?.toLocaleString()}</td>
                </tr>
                <tr className="font-mono">
                    <td className="py-0.5 text-left font-sans">Loss</td>
                    <td className="py-0.5 border-x px-2">{(Math.round(( data?.total_loss) * 100) / 100)?.toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.loss_stock_a_returns * 100)) / 100)?.toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.loss_stock_b_returns * 100)) / 100)?.toLocaleString()}</td>
                </tr>
                <tr className="font-mono border-t">
                    <td className="py-0.5 text-left font-sans">Trades</td>
                    <td className="py-0.5 border-x px-2">{(data?.net_returns?.length)?.toLocaleString()}</td>
                    <td className="py-0.5">{(data?.stock_a_returns?.length)?.toLocaleString()}</td>
                    <td className="py-0.5">{(data?.stock_a_returns?.length)?.toLocaleString()}</td>
                </tr>
                <tr className="font-mono">
                    <td className="py-0.5 text-left font-sans">Winning Trades</td>
                    <td className="py-0.5 border-x px-2">{(data?.net_returns?.filter(el => el > 1).length)?.toLocaleString()}</td>
                    <td className="py-0.5">{(data?.stock_a_returns?.filter(el => el > 0).length)?.toLocaleString()}</td>
                    <td className="py-0.5">{(data?.stock_b_returns?.filter(el => el > 0).length)?.toLocaleString()}</td>
                </tr>
                <tr className="font-mono">
                    <td className="py-0.5 text-left font-sans">Loosing Trades</td>
                    <td className="py-0.5 border-x px-2">{(data?.net_returns?.filter(el => el < 1).length)?.toLocaleString()}</td>
                    <td className="py-0.5">{(data?.stock_a_returns?.filter(el => el < 0).length)?.toLocaleString()}</td>
                    <td className="py-0.5">{(data?.stock_b_returns?.filter(el => el < 0).length)?.toLocaleString()}</td>
                </tr>
                <tr className="font-mono border-t">
                    <td className="py-0.5 text-left font-sans">% Profitable</td>
                    <td className="py-0.5 border-x px-2">{(Math.round((data?.net_returns?.filter(el => el > 1)?.length / data?.net_returns?.length) * 100 * 100) / 100).toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.stock_a_returns?.filter(el => el > 0)?.length / data?.stock_a_returns?.length) * 100 * 100) / 100).toLocaleString()}</td>
                    <td className="py-0.5">{(Math.round((data?.stock_b_returns?.filter(el => el > 0)?.length / data?.stock_a_returns?.length) * 100 * 100) / 100).toLocaleString()}</td>
                </tr>
            </table>

            <h2 className="text-xl font-semibold mt-8">
                Adjusted Closing Prices of {oldInputs["stock_a"]} and {oldInputs["stock_b"]}
            </h2>
            <p className="mb-2 text-sm">
                For the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}. There are {data[oldInputs["stock_a"]]?.length} trading days in this period.
            </p>
            <TimeseriesChart show = {showData} data = {priceData} options = {options}></TimeseriesChart>
            <p className="text-xs text-gray-500 dark:text-gray-400 pt-1"><span className="font-bold">Figure 1</span>: Adjusted Closing Prices of {oldInputs["stock_a"]} and {oldInputs["stock_b"]} for the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.</p>

            <h2 className="text-xl font-semibold mt-8">
                Spread of Adjusted Closing Prices of {oldInputs["stock_a"]} and {oldInputs["stock_b"]}
            </h2>
            <p className="mb-2 text-sm">
                For the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.
            </p>

            <TimeseriesChart show = {showData} data = {spreadData} options = {{}}></TimeseriesChart>
            <p className="text-xs text-gray-500 dark:text-gray-400 pt-1"><span className="font-bold">Figure 2</span>: Spread of Adjusted Closing Prices of {oldInputs["stock_a"]} and {oldInputs["stock_b"]} for the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.</p>

            <h2 className="text-xl font-semibold mt-8">
                Rolling Cointegration OLS Regression Coefficients
            </h2>
            <p className="text-sm">
                Regression coefficients for OLS generated with an {oldInputs["regression_period"]} day regression period.
            </p>
            <p className="mb-2 mt-2 mono text-sm text-center">
                stock_a = alpha + beta * stock_b
            </p>
            <TimeseriesChart show = {showData} data = {regressionCoefficientsData} options = {options}></TimeseriesChart>
            <p className="text-xs text-gray-500 dark:text-gray-400 pt-1"><span className="font-bold">Figure 3</span>: {oldInputs["regression_period"]}-Day Rolling Cointegration OLS Regression Coefficients.</p>


            <h2 className="text-xl font-semibold mt-8">
                Z - Score of Spread
            </h2>
            <p className="mb-2 text-sm">
                For the period of {oldInputs["start_date"]} to {oldInputs["end_date"]} using a {oldInputs["regression_period"]}-day rolling standard deviation.
            </p>

            {/* 94 194 105 rgb(94,194,105) - green */}
            {/* 221 82 76 rgb(221,82,76) - red */}

            <TimeseriesChart show = {showData} data = {zScoreData} options = {{
                plugins: {
                    // title: {
                    //     display: true,
                    //     text: "Z - Score of Pairs Trade"
                    // },
                    annotation: {
                        annotations: {
                            entry_z1: {
                                type: 'line',
                                borderDash: [6,4],
                                borderWidth: 1,
                                yMin: oldInputs["entry_z"],
                                yMax: oldInputs["entry_z"],
                                borderColor: 'rgb(94,194,105)',
                            },
                            entry_z2: {
                                type: 'line',
                                yMin: -oldInputs["entry_z"],
                                yMax: -oldInputs["entry_z"],
                                borderColor: 'rgb(94,194,105)',
                                borderDash: [6,4],
                                borderWidth: 1,
                            },
                            exit_z1: {
                                type: 'line',
                                yMin: oldInputs["exit_z"],
                                yMax: oldInputs["exit_z"],
                                borderColor: 'rgb(221,82,76)',
                                borderDash: [6,4],
                                borderWidth: 1,
                            },
                            exit_z2: {
                                type: 'line',
                                yMin: -oldInputs["exit_z"],
                                yMax: -oldInputs["exit_z"],
                                borderColor: 'rgb(221,82,76)',
                                borderDash: [6,4],
                                borderWidth: 1,
                            }
                        }
                    }
                }}}></TimeseriesChart>

                <p className="text-xs text-gray-500 pt-1 dark:text-gray-400"><span className="font-bold">Figure 4</span>: Z - Score of Spread for the period of {oldInputs["start_date"]} to {oldInputs["end_date"]} using a {oldInputs["regression_period"]}-day rolling standard deviation.</p>

                <h2 className="text-xl font-semibold mt-8">
                    Trade Log
                </h2>
                <p className="mb-2 text-sm">
                    This is the trade log between the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.
                </p>    

                <table className="w-full mono">
                    <tr className="border-b text-left text-sm">
                        <th className="py-2 pl-2">Date</th>
                        <th className="py-2 pl-2">Ticker</th>
                        <th className="py-2 pl-2">Operation</th>
                        <th className="py-2 pl-2 text-right">Z - Score</th>
                        <th className="py-2 pl-2 text-right">Price</th>
                        <th className="py-2 pl-2 pr-2 text-right">Profit / (Loss)</th>
                    </tr>
                    
                    {showData && data?.backtest && data?.backtest[0] ? data.backtest.map(row => <tr className={`${row.operation == "Buy" ? "bg-green-100 dark:bg-green-800 hover:bg-green-200 dark:hover:bg-green-900" : ""} ${row.operation == "Sell" ? "bg-red-100 dark:bg-red-800 hover:bg-red-200 dark:hover:bg-red-900" : ""} dark:text-gray-300 text-gray-600`}>
                        <td className="py-1 text-sm pl-2">{row.date}</td>
                        <td className="py-1 text-sm pl-2">{row.ticker}</td>
                        <td className="py-1 text-sm pl-2">
                            {row.operation == "Buy" || row.operation =="Exit Sell" ? <span className="text-green-500 dark:text-green-300">{row.operation}</span> : ""}
                            {row.operation == "Sell" || row.operation =="Exit Buy" ? <span className="text-red-500 dark:text-red-300">{row.operation}</span> : ""}
                        </td>
                        <td className="py-1 pl-2 text-sm text-right">{row["z_score"]}</td>
                        <td className="py-1 pl-2 pr-2 text-sm text-right">{row.price}</td>
                        <td className="py-1 pl-2 pr-2 text-sm text-right">{row.profit_loss}</td>
                    </tr>) : ""}
                </table>

                </div> 
                : ""}

                { apiError ? <div className="bg-slate-100 py-3 px-2 border-t-4 text-sm border-red-500 text-slate-600 dark:text-slate-400 dark:bg-gray-950">
                    <h4 className="text-lg text-red-600 font-medium">Something went wrong.</h4>
                    {apiError}
                </div> : "" }
            </BaseTemplate>)
}

export default BacktestingPairsTradeProject